import React from 'react';

import { Grid, Typography } from '@material-ui/core';

import { StatusAlert } from '../../../menu/model/status-alert';
import { getAlertIcon } from '../../helpers/get-alert-icon/get-alert-icon';
import { useStyles, colorStyles } from './alert-box.styles';

interface Props {
  type: StatusAlert;
  title: string;
  text: string;
}

export default function AlertBox({ type = 'warning', title, text }: Props) {
  const classes = useStyles();
  const typeStyles = colorStyles();

  return (
    <Grid id='container-alert' container className={`${classes.container} ${typeStyles[type]}`}>
      <Grid item>
        <div className={classes.icon}>{getAlertIcon[type]}</div>
      </Grid>

      <Grid item>
        <Grid item>
          <Typography variant='caption' className={classes.title}>
            {title}
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant='caption' className={classes.subtitle}>
            {text}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
