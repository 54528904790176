import React, { ReactElement } from 'react';

import { AppBar, Toolbar, IconButton, Box, Typography, Divider, Avatar } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import Logo from '../../../assets/pede-pronto-logo-large.png';
import { User } from '../../generated/graphql';
import { getUserFullName } from '../../helpers/get-user-full-name/get-user-full-name';
import ProfileMenu from '../profile-menu/profile-menu';
import { useStyles } from './header.styles';

interface Props {
  user?: User;
  handleDrawerToggle: VoidFunction;
}

export default function Header(props: Props): ReactElement {
  const classes = useStyles();

  const [firstName, lastName] = getUserFullName(props?.user as User);

  return (
    <Box className={classes.container}>
      <AppBar className={classes.contentContainer}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={props.handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <img className={classes.logo} src={Logo} alt='Pede Pronto' />
          <Box className={classes.profileContainer}>
            <Avatar className={classes.avatar} id='user-avatar'>
              {`${firstName.charAt(0)}${lastName.charAt(0)}`}
            </Avatar>
            <Typography className={classes.userName}>{`${firstName} ${lastName}`}</Typography>
            <ProfileMenu />
          </Box>
        </Toolbar>
      </AppBar>
      <Divider />
    </Box>
  );
}
