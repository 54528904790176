/**
 * @todo This is a temporary snippet of code to
 * present all pages available only to operations staff
 */

export const isSuperUser = (email: string) => {
  return (
    email.includes('onyo.com') ||
    email.includes('pedepronto.com.br') ||
    email.includes('@yhbrasil.com') ||
    email.includes('@neobpo.com.br') ||
    email.includes('pedepronto.com.br') ||
    email.includes('digisystem.com.br')
  );
};
