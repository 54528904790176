import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      margin: theme.spacing(1, 'auto', 0),
      maxWidth: '20rem',
    },
  })
);
