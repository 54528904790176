import { GetUserFullName } from '../../model/get-user-full-name';

export const getUserFullName: GetUserFullName = (user) => {
  if (user.lastName) {
    return [user.firstName, user.lastName] as string[];
  }

  const splittedName = user?.firstName?.split(' ');

  if (splittedName && splittedName.length > 1) {
    return [splittedName[0], splittedName[1]] as string[];
  }

  return [user.firstName, ''] as string[];
};
