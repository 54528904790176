import React, { useCallback } from 'react';

import { Box, Typography, Button } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from './network-error.styles';

interface Props {
  errorMsg: string;
  retry: VoidFunction;
  compact?: boolean;
}

export default function NetworkError(props: Props) {
  const classes = useStyles();

  const { retry } = props;
  const onRetryClick = useCallback(() => {
    if (retry) {
      retry();
    }
  }, [retry]);

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      className={classes.root}
    >
      {!props.compact && (
        <Box className={classes.element}>
          <Typography variant='h5' align='center'>
            Ops,
          </Typography>
        </Box>
      )}
      <Box className={clsx({ [classes.element]: !props.compact })}>
        <Typography align='center'>{props.errorMsg}</Typography>
      </Box>
      <Box className={clsx({ [classes.element]: !props.compact })}>
        <Button
          color='primary'
          onClick={onRetryClick}
          id='retry-btn'
          variant={props.compact ? 'text' : 'contained'}
        >
          Tentar novamente
        </Button>
      </Box>
    </Box>
  );
}
