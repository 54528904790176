import React, { ReactElement } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import Icon from '../icon/icon';
import { useStyles } from './dialog-container.styles';

interface Props {
  openModal: boolean;
  handleModalClose(): void;
  children: ReactElement;
  title: string;
  id: string;
  containerClassname?: string;
  closeButton?: boolean;
  disableBackdrop?: boolean;
  disableEscapeKeyDown?: boolean;
}

export default function DialogContainer(props: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = !useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Dialog
      id={props.id}
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth='xs'
      open={props.openModal}
      className={props.containerClassname}
      onClose={props.handleModalClose}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant='body1' className={`${classes.textWeight} ${classes.textColor}`}>
          {props.title}
        </Typography>
        {props?.closeButton && (
          <IconButton
            id={`${props.id}-close`}
            edge='start'
            onClick={props.handleModalClose}
            aria-label='close'
            className={classes.buttonClose}
          >
            <Icon name='close' />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent className={classes.contentContainer}>{props.children}</DialogContent>
    </Dialog>
  );
}
