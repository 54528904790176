import { getOnlyDigits } from '../get-only-digits/get-only-digits';

export function getPhoneNumberRegex(): RegExp {
  return new RegExp('^\\(\\d{2}\\)\\ (\\d{4}|\\d{5})\\-\\d{4}$');
}

/*
 * Formats a number string into the regular: "(XX) XXXX-XXXX" or cellphone: "(XX) XXXXX-XXXX"
 * Note: all non numeric characters will be filtered out of the input string.
 */
export function formatPhoneNumber(value?: string): string {
  if (!value) {
    return '';
  }

  let phoneNumber = getOnlyDigits(value);

  // Begin formatting from 3 charactes and above, to make sure we can remove the '()' from the prefix.
  if (phoneNumber.length < 3) {
    return phoneNumber;
  }

  let boundary: number;
  if (phoneNumber.length <= 10) {
    boundary = 6;
  } else {
    phoneNumber = phoneNumber.substring(0, 11);
    boundary = 7;
  }

  const ddd = phoneNumber.substring(0, 2);
  const prefix = phoneNumber.substring(2, boundary);
  const sufix = phoneNumber.substring(boundary);

  let formattedValue: string;
  if (sufix === '') {
    formattedValue = `(${ddd}) ${prefix}`;
  } else {
    formattedValue = `(${ddd}) ${prefix}-${sufix}`;
  }

  return formattedValue;
}

export function formatAsLocalNumber(value?: string): string {
  if (!value) {
    return '';
  }

  if (value.length <= 11) {
    return formatPhoneNumber(value);
  }

  const phoneNumber = getOnlyDigits(value);
  const localNumber = phoneNumber.substring(2);

  return formatPhoneNumber(localNumber);
}
