import {
  messageNewCategory,
  messageDeleteCategory,
  messageUpdateCategory,
  messageActiveCategory,
} from '../../../../common/constants/message-alert-categories';
import { StatusAlert } from '../../../menu/model/status-alert';

type Action = 'create' | 'delete' | 'update' | 'active';

const actionType = {
  create: messageNewCategory,
  delete: messageDeleteCategory,
  update: messageUpdateCategory,
  active: messageActiveCategory,
};

export default function setStatusMessageAlert(action: Action, status: StatusAlert) {
  return {
    type: actionType[action][status].TYPE,
    title: actionType[action][status].TITLE,
    text: actionType[action][status].TEXT,
  };
}
