import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      lineHeight: '1.25rem',
      '&.MuiFormLabel-root.Mui-focused:not(.Mui-error)': {
        color: theme.typography.color?.main,
      },
    },
    input: {
      margin: theme.spacing(0.5, 0, 0),
      minHeight: '3rem',
      '&:hover': {
        boxShadow: `0px 4px 16px ${theme.palette.grey[300]}`,
      },
      '&:hover > fieldset, &.Mui-focused > fieldset': {
        borderColor: `${theme.palette.grey[300]} !important`,
      },
      '& input, & textarea': {
        color: theme.typography?.color?.main,
        padding: theme.spacing(1.25, 1.5),
      },
      backgroundColor: theme.palette.common.white,
    },
    disabled: {
      opacity: 0.5,
    },
  })
);
