export const PRODUCTION = 'PRODUCTION';
export const STAGING = 'STAGING';
export const DEVELOPMENT = 'DEVELOPMENT';

export interface AppConfig {
  sentryDSN?: string;
  productPaginationStep: number;
  rowsPerPageTable: number;
  productSearchBoxLimit: number;
  maxFileSize: number;
  gmapsSrc: string;
  gtmId: string;
  firebaseConfig: {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };
  webAppUrl: string;
  simpleMinimumChoices: number;
  timeout: number;
  pollInterval: number;
  gtmEncryptionKey: string;
}

export interface Endpoints {
  api: string;
  graphql: string;
  gsBucket: string;
  s3Bucket: string;
  econboarding: string;
}
