import { event } from '../../../common/helpers/gtags/gtags';
import { UnionParamName, UnionEventName } from '../model/analytics-config';

type Properties = Partial<{
  [key in UnionParamName]: any;
}>;

type AnalyticsError = {
  error_type: string;
  error_code: number;
  error_message?: string;
};

export const logEvent = event<{ event: UnionEventName }, { properties: Properties }>();

export const logEventError = ({ error_code, error_type, error_message }: AnalyticsError) =>
  logEvent('maiden', { error_code, error_message, error_type });
