import React, { ReactElement } from 'react';

import Icon from '../../../../common/components/icon/icon';

type Type = Partial<{
  [key in any]: ReactElement;
}>;

export const getAlertIcon: Type = {
  success: <Icon name='success' size='large' color='successDark' viewBox='0 0 24 24' />,
  warning: <Icon name='warning' size='large' color='warning' viewBox='0 0 24 24' />,
  error: <Icon name='error' size='large' color='error' viewBox='0 0 24 24' />,
};
