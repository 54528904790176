import React, { useCallback, useEffect, useState } from 'react';

import { Grid, Typography } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { logError, logEvent, logEventError } from 'pp-admin-commons';
import { useParams } from 'react-router';

import {
  useGetCategoriesQuery,
  usePostCategoryMutation,
  useUpdateCategoryMutation,
} from '../../../api';
import AlertBox from '../../../features/main-menu/components/alert-box/alert-box';
import { filterExistingCategory } from '../../../features/main-menu/helpers/filter-existing-category/filter-existing-category';
import setStatusMessageAlert from '../../../features/main-menu/helpers/set-status-message-alert/set-status-message-alert';
import { openMessage } from '../../../store/message-alert/message-alert.slice';
import { toggleCategory } from '../../../store/modal-category/modal-category.slice';
import { ErrorsCode } from '../../analytics/enum/log-errors-code';
import { useAppSelector, useAppDispatch } from '../../hooks/use-redux/use-redux';
import DialogContainer from '../dialog-container/dialog-container';
import GenericButton from '../generic-button/generic-button';
import GenericTextField from '../generic-text-field/generic-text-field';
import ProgressBox from '../progress-box/progress-box';
import { useStyles } from './modal-category.styles';

export default function ModalCategory() {
  const classes = useStyles();

  const { companyId } = useParams<{
    companyId: string;
  }>();

  const categoriesResponse = useGetCategoriesQuery(companyId, {
    skip: !companyId,
  });
  const dispatch = useAppDispatch();
  const [showWarning, setShowWarning] = useState(false);
  const [hasExistingCategory, setHasExistingCategory] = useState(false);
  const [categoryNameFormValue, setCategoryNameFormValue] = useState<string>();
  const stateCategory = useAppSelector((state) => state.modalCategory);
  const [postNewCategory, { isError: newError, isSuccess: newSuccess, isLoading: newLoading }] =
    usePostCategoryMutation();
  const [
    updateCategory,
    { isError: updateError, isSuccess: updateSuccess, isLoading: updateLoading },
  ] = useUpdateCategoryMutation();

  useEffect(() => {
    setCategoryNameFormValue(stateCategory?.category?.name);
  }, [stateCategory]);

  useEffect(() => {
    if (newSuccess) {
      dispatch(openMessage(setStatusMessageAlert('create', 'success')));
      categoriesResponse.refetch();
    }

    if (newError) {
      dispatch(openMessage(setStatusMessageAlert('create', 'error')));
      logEventError({
        ERROR_CODE: ErrorsCode.ADD_ITEM_CATEGORY,
        ERROR_TYPE: 'add_item_category',
        ERROR_MESSAGE: 'Error add item category',
      });
    }

    if (!newLoading) {
      handleDispatchClose();
    }
  }, [newError, newSuccess, newLoading]);

  useEffect(() => {
    if (updateSuccess) {
      dispatch(openMessage(setStatusMessageAlert('update', 'success')));
      categoriesResponse.refetch();
    }

    if (updateError) {
      dispatch(openMessage(setStatusMessageAlert('update', 'error')));
      logEventError({
        ERROR_CODE: ErrorsCode.UPDATE_ITEM_CATEGORY,
        ERROR_TYPE: 'update_item_category',
        ERROR_MESSAGE: 'Error update item category',
      });
    }

    if (!updateLoading) {
      handleDispatchClose();
    }
  }, [updateError, updateSuccess, updateLoading]);

  const handleOnChange = useCallback(
    (value) => {
      setHasExistingCategory(false);
      if (
        stateCategory.categories &&
        filterExistingCategory(value.target.value, stateCategory.categories)
      ) {
        setHasExistingCategory(true);
      }

      setCategoryNameFormValue(value.target.value);
    },
    [stateCategory]
  );

  const handleSubmit = useCallback(
    (values) => {
      if (!showWarning) {
        if (stateCategory.isEdit) {
          updateCategory({
            name: values.categoryName,
            externalMerchantId: companyId,
            categoryId: stateCategory?.category?.id,
            active: stateCategory?.category?.active,
            externalCategoryId: stateCategory?.category?.externalCategoryId,
            index: stateCategory?.category?.index,
          });
          logEvent<'update_item_category', keyof { company_id: string; item_category: string }>()(
            'update_item_category',
            {
              company_id: companyId,
              item_category: values.categoryName,
            }
          );
        } else {
          postNewCategory({ name: values.categoryName, externalMerchantId: companyId });
          logEvent<'add_item_category', keyof { company_id: string; item_category: string }>()(
            'add_item_category',
            {
              company_id: companyId,
              item_category: values.categoryName,
            }
          );
        }
      }

      resetInfos();
    },
    [companyId, showWarning, stateCategory]
  );

  const handleDispatchClose = useCallback(() => {
    dispatch(
      toggleCategory({
        category: { name: '' },
        open: false,
      })
    );

    resetInfos();
  }, []);

  const verifyInfosToCancel = useCallback(() => {
    if (stateCategory?.category?.name !== categoryNameFormValue && !hasExistingCategory) {
      setShowWarning(true);
    } else {
      handleDispatchClose();
    }
  }, [stateCategory, categoryNameFormValue]);

  const resetInfos = () => {
    setHasExistingCategory(false);
    setShowWarning(false);
    setCategoryNameFormValue('');
  };

  if (newLoading || updateLoading) {
    return (
      <DialogContainer
        id='modal-add-category'
        title=''
        openModal={stateCategory?.open}
        closeButton={false}
        handleModalClose={verifyInfosToCancel}
        containerClassname={classes.modalContainer}
      >
        <ProgressBox
          customStyles={classes.modalContainerLoading}
          loadingText='Estamos processando sua ação'
        />
      </DialogContainer>
    );
  }

  const CancelButton = () => {
    if (showWarning && !hasExistingCategory) {
      return (
        <GenericButton
          type='button'
          id='cancel'
          size='large'
          color='outline'
          onClick={handleDispatchClose}
          className={classes.buttons}
        >
          Descartar edições
        </GenericButton>
      );
    }

    return (
      <GenericButton
        type='button'
        id='cancel'
        size='large'
        color='outline'
        onClick={verifyInfosToCancel}
        className={classes.buttons}
      >
        Cancelar
      </GenericButton>
    );
  };

  return (
    <DialogContainer
      id='modal-add-category'
      title={!stateCategory?.category?.name?.length ? 'Adicionar categoria' : categoryNameFormValue}
      openModal={stateCategory?.open}
      closeButton={true}
      handleModalClose={verifyInfosToCancel}
      containerClassname={classes.modalContainer}
    >
      <Grid>
        {showWarning && !hasExistingCategory && (
          <div>
            <AlertBox
              type='warning'
              title='Atenção'
              text='Você ainda não salvou suas edições. Deseja sair sem salvar?'
            />
          </div>
        )}

        {hasExistingCategory && (
          <div>
            <AlertBox
              type='error'
              title='Nome da categoria já existe!'
              text='Por favor, informe um outro nome para esta categoria.'
            />
          </div>
        )}

        <Formik
          initialValues={{ categoryName: stateCategory?.category?.name }}
          onSubmit={handleSubmit}
        >
          <Form id='modal-category' onChange={handleOnChange}>
            <GenericTextField
              id='categoryName'
              label='Nome da categoria'
              name='categoryName'
              fieldType='text'
              maxLength={40}
              className={classes.inputCategory}
              autoComplete='off'
            />
            <Typography
              variant='caption'
              className={classes.maxLength}
            >{`${categoryNameFormValue?.length}/40 caracteres`}</Typography>
            <Grid className={classes.containerButtons}>
              <CancelButton />
              <GenericButton
                type='submit'
                id='add'
                size='large'
                color='primary'
                className={classes.buttons}
                disabled={
                  stateCategory?.category?.name === categoryNameFormValue || hasExistingCategory
                }
              >
                {showWarning && !hasExistingCategory ? 'Continuar editando' : 'Salvar'}
              </GenericButton>
            </Grid>
          </Form>
        </Formik>
      </Grid>
    </DialogContainer>
  );
}
