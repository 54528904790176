import { getOnlyDigits } from '../get-only-digits/get-only-digits';

/**
 * formats the CPF as XXX.XXX.XXX-YY
 */
export function formatCPF(cpf?: string): string {
  if (!cpf) {
    return '';
  }

  const numCPF = getOnlyDigits(cpf);

  if (numCPF.length > 9) {
    return `${numCPF.substring(0, 3)}.${numCPF.substring(3, 6)}.${numCPF.substring(
      6,
      9
    )}-${numCPF.substring(9, 11)}`;
  } else if (numCPF.length > 6) {
    return `${numCPF.substring(0, 3)}.${numCPF.substring(3, 6)}.${numCPF.substring(6)}`;
  } else if (numCPF.length > 3) {
    return `${numCPF.substring(0, 3)}.${numCPF.substring(3)}`;
  } else {
    return numCPF;
  }
}
