import { createContext } from 'react';

import { SelfJourneyLocationOptions } from '../../../model/self-journey-location-options';

type Props = {
  setCurrentSelfJourneyPage: (page: SelfJourneyLocationOptions) => void;
  currentSelfJourneyPage: SelfJourneyLocationOptions | null;
};

export default createContext<Props>({} as Props);
