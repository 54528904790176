export const messageNewCategory = {
  success: {
    TYPE: 'success',
    TITLE: 'Pronto :)',
    TEXT: 'A categoria foi cadastrada com sucesso!',
  },
  warning: {
    TYPE: 'warning',
    TITLE: '',
    TEXT: '',
  },
  error: {
    TYPE: 'error',
    TITLE: 'Falha ao cadastrar a categoria :(',
    TEXT: 'A categoria não pode ser criada.',
  },
};

export const messageDeleteCategory = {
  success: {
    TYPE: 'success',
    TITLE: 'Categoria Excluída',
    TEXT: 'A categoria foi excluída com sucesso!',
  },
  warning: {
    TYPE: 'warning',
    TITLE: '',
    TEXT: '',
  },
  error: {
    TYPE: 'error',
    TITLE: 'Falha ao excluir a categoria :(',
    TEXT: 'A categoria não pode ser excluída.',
  },
};

export const messageUpdateCategory = {
  success: {
    TYPE: 'success',
    TITLE: 'Pronto :)',
    TEXT: 'A categoria foi editada com sucesso!',
  },
  warning: {
    TYPE: 'warning',
    TITLE: '',
    TEXT: '',
  },
  error: {
    TYPE: 'error',
    TITLE: 'Falha ao editar a categoria :(',
    TEXT: 'A categoria não pode ser editada.',
  },
};

export const messageActiveCategory = {
  success: {
    TYPE: 'success',
    TITLE: 'Pronto :)',
    TEXT: 'Situação da categoria alterada com sucesso!',
  },
  warning: {
    TYPE: 'warning',
    TITLE: 'Atenção!',
    TEXT: 'A categoria não pode ficar indisponível.',
  },
  error: {
    TYPE: 'error',
    TITLE: 'Falha ao realizar a ação :(',
    TEXT: 'Não foi possível concluir a ação, tente novamente mais tarde.',
  },
};
