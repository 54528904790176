import React, { useEffect, ReactElement, Suspense } from 'react';

import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';

import withErrorBoundary from './common/components/error-boundary/error-boundary';
import { PrivateRoute } from './common/components/private-route/private-route';
import Progress from './common/components/progress/progress';
import { getLocationUrl } from './common/helpers/get-location-url/get-location-url';
import { pageview } from './common/helpers/gtags/gtags';
import { isAuthenticated } from './common/helpers/is-authenticated/is-authenticated';

const Menu = React.lazy(() => import('./features/menu/components/menu/menu'));

const ProductEdition = React.lazy(
  () => import('./features/menu/components/product-edition/product-edition')
);
const ToggleMainMenu = React.lazy(
  () => import('./features/menu/components/toggle-main-menu/toggle-main-menu')
);

const UploadMenu = React.lazy(() => import('./features/menu/components/upload-menu/upload-menu'));
const NewProduct = React.lazy(() => import('./features/menu/components/new-product/new-product'));

export default function Routes(): ReactElement {
  useEffect(() => {
    let url: URL = getLocationUrl(window.location);

    pageview(url);
  }, []);

  return (
    <BrowserRouter>
      <Suspense fallback={<Progress />}>
        <Switch>
          {isAuthenticated() && (
            <Route exact={true} path='/'>
              <Redirect to='/menu' />
            </Route>
          )}
          <PrivateRoute exact path='/menu' component={withErrorBoundary(Menu)} />
          <PrivateRoute
            exact
            path='/menu/main-menu/:brandId/:companyId/:productOrigin'
            component={withErrorBoundary(ToggleMainMenu)}
          />
          <PrivateRoute
            exact
            path='/menu/main-menu/:brandId/:companyId/:productOrigin/:categoryId/category'
            component={withErrorBoundary(ToggleMainMenu)}
          />
          <PrivateRoute
            exact
            path='/menu/upload-menu/:brandId/:companyId'
            component={withErrorBoundary(UploadMenu)}
          />
          <PrivateRoute
            exact
            path='/menu/new-product/:brandId/:companyId/:productOrigin'
            component={withErrorBoundary(NewProduct)}
          />
          <PrivateRoute
            exact
            path='/menu/product-edition/:brandId/:companyId/:productCompanyId/:productOrigin'
            component={withErrorBoundary(ProductEdition)}
          />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}
