import React from 'react';

import { IconButton } from '@material-ui/core';

import { ColorOptions, UnionIconSizeOptions, UnionIconOptions } from '../../model/theme-options';
import Icon from '../icon/icon';

interface Props {
  size?: UnionIconSizeOptions;
  id: string;
  color: ColorOptions;
  disabled?: boolean;
  onClick?(event: React.MouseEvent<HTMLButtonElement>): void;
  type?: 'submit' | 'reset' | 'button';
  className?: string;
  name: UnionIconOptions;
}

export default function GenericActionButton(props: Props) {
  return (
    <IconButton id={props.id} type={props.type} onClick={props.onClick} disabled={props.disabled}>
      <Icon
        name={props.name}
        size={props.size ?? 'medium'}
        color={props.disabled ? 'greyBase' : props.color}
      />
    </IconButton>
  );
}
