import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette?.neutral?.light,
    },
    textColor: {
      color: theme.typography?.color?.main,
    },
    dialogTitle: {
      backgroundColor: theme.palette?.neutral?.light,
    },
    textWeight: {
      fontWeight: theme.typography?.fontWeightMediumBold,
    },
    buttonClose: {
      position: 'absolute',
      top: theme.spacing(1),
      right: 0,
    },
  })
);
