import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'fixed',
      top: '4rem',
      right: 0,
      zIndex: theme.zIndex.tooltip,
    },
    boxAlert: {
      width: '33.25rem',
      height: '5.188rem',
      display: 'flex',
      background: theme.palette.success.light,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(2),
      alignItems: 'center',
      position: 'relative',
      marginBottom: theme.spacing(0.5),
    },
    title: {
      color: theme.typography?.color?.darkest,
      fontWeight: theme.typography.fontWeightMediumBold,
    },
    icon: {
      color: theme.typography?.color?.darkest,
      margin: theme.spacing(1.5, 1, 0, 0),
    },
    iconClose: {
      position: 'absolute',
      top: '0.938rem',
      right: '0.938rem',
      cursor: 'pointer',
    },
  })
);

export const colorStyles = makeStyles((theme: Theme) =>
  createStyles({
    success: {
      backgroundColor: theme.palette.success.superLightest,
    },
    warning: {
      backgroundColor: theme.palette.warning.superLightest,
    },
    error: {
      backgroundColor: theme.palette.error.light,
    },
  })
);
