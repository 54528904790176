import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
    },
    helpIcon: {
      padding: theme.spacing(1),
      boxSizing: 'unset',
    },
    helpText: {
      padding: theme.spacing(1),
      color: theme.palette.text.primary,
      textDecoration: 'none',
    },
  })
);
