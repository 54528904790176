import { modalCategoryReducers } from './modal-category.model';

export const modalCategoryReducer: modalCategoryReducers = {
  toggleCategory(state, action) {
    const { category, open, isEdit, categories } = action.payload;
    state.category = category;
    state.open = open;
    state.isEdit = isEdit;
    state.categories = categories;
  },
};
