import React, { useCallback, useEffect } from 'react';

import { Box, Fade, Typography } from '@material-ui/core';

import Icon from '../../../common/components/icon/icon';
import { useAppSelector, useAppDispatch } from '../../../common/hooks/use-redux/use-redux';
import { getAlertIcon } from '../../../features/main-menu/helpers/get-alert-icon/get-alert-icon';
import { closeMessage } from '../../../store/message-alert/message-alert.slice';
import { useStyles, colorStyles } from './message-alert.styles';

export default function MessageAlert() {
  const classes = useStyles();
  const typeStyles = colorStyles();
  const alertsToShow = useAppSelector((state) => state.messageAlert);
  const dispatch = useAppDispatch();

  const handleCloseAlert = useCallback((index) => {
    dispatch(closeMessage({ index: index }));
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(closeMessage({ index: 0 }));
    }, 4000);

    return () => {
      clearTimeout(timer);
    };
  }, [alertsToShow]);

  return (
    <div className={classes.container}>
      {alertsToShow.map((info, index) => (
        <Fade in={true} timeout={1000} key={index}>
          <Box
            id='box'
            data-testid='box'
            className={`${classes.boxAlert} ${typeStyles[info.type]}`}
          >
            <Box>
              <div className={classes.icon}>{getAlertIcon[info.type]}</div>
            </Box>

            <Box>
              <Box>
                <Typography datatest-id='title' variant='body1' className={classes.title}>
                  {info.title}
                </Typography>
              </Box>

              <Box>
                <Typography variant='body2'>{info.text}</Typography>
              </Box>
            </Box>
            <div className={classes.iconClose} onClick={() => handleCloseAlert(index)}>
              <Icon name='close' size='medium' />
            </div>
          </Box>
        </Fade>
      ))}
    </div>
  );
}
