import React, { Component } from 'react';

import { Box, Button, Container, Typography, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import * as Sentry from '@sentry/browser';
import flowRight from 'lodash/flowRight';

import ContactUs from '../contact-us/contact-us';
import StandardDialog from '../standard-dialog/standard-dialog';
import { styles } from './error-boundary.styles';

interface State {
  hasError: boolean;
  openDialog: boolean;
}
type WrapperProps = WithStyles<typeof styles>;
const withErrorBoundary = <P extends {}>(WrappedComponent: React.ComponentType<P>) => {
  type Props = P & WrapperProps;
  return class ErrorBoundary extends Component<Props, State> {
    public static getDerivedStateFromError(error: any) {
      return { hasError: true, openDialog: error.name === 'ChunkLoadError' };
    }

    constructor(props: Props) {
      super(props);
      this.state = { hasError: false, openDialog: false };
    }

    public componentDidCatch(error: any, errorInfo: any) {
      if (process.env.NODE_ENV !== 'test') {
        console.error(errorInfo, error);
        Sentry.setExtra('errorInfo', errorInfo);
        Sentry.captureException(error);
      }
    }

    public onBackClick() {
      window.location.reload();
    }

    public render() {
      const { classes, ...props } = this.props;

      if (this.state.openDialog) {
        return (
          <StandardDialog
            id='chunk-load-error-dialog'
            title='Atenção!'
            text='O app acaba de ser atualizado. Por favor, clique em Atualizar para acessar a nova versão.'
            open={this.state.openDialog}
            closeLabel='Atualizar'
            onCloseClick={() => {
              window.location.reload();
            }}
            disableBackdropClick={true}
          />
        );
      }

      if (this.state.hasError) {
        return (
          <Container className={classes.container} id='error-boundary'>
            <Box className={classes.contentContainer}>
              <Typography className={classes.textElement} variant='h5' align='center'>
                Ops
              </Typography>
              <Typography className={classes.textElement} align='center'>
                Ocorreu um erro inesperado. Tente novamente ou entre em contato com o nosso suporte.
              </Typography>
              <ContactUs />
              <Box className={classes.tryContainer}>
                <Button color='primary' variant='contained' onClick={this.onBackClick}>
                  Tentar novamente
                </Button>
              </Box>
            </Box>
          </Container>
        );
      }
      return <WrappedComponent {...(props as P)} />;
    }
  };
};
export default flowRight(withStyles(styles), withErrorBoundary);
