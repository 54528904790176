import React from 'react';

import { ColorOptions, UnionIconOptions, UnionIconSizeOptions } from '../../model/theme-options';
import { getSize } from './helpers/get-size';
import { IconPath } from './helpers/icon-path';
import { useGetColor } from './helpers/use-get-color';

interface Props {
  name: UnionIconOptions;
  size?: UnionIconSizeOptions;
  color?: ColorOptions;
  icon?: string;
  className?: string;
  style?: object;
  viewBox?: string;
}

export default function Icon({ name, size, color, viewBox = '0 0 40 40', className }: Props) {
  const iconSize = getSize(size);
  const selectedColor = color ?? 'default';
  const fillColor = useGetColor(selectedColor);

  return (
    <svg
      id='icon'
      className={className}
      viewBox={viewBox}
      width={`${iconSize}px`}
      height={`${iconSize}px`}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <path id='icon-path' fill={fillColor} d={IconPath[name]} />
    </svg>
  );
}
