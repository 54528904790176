import { PRODUCTION } from '../../../common/model/config';
import { GTagPageView, GTagEventModel } from '../../model/gtags';

export const pageview: GTagPageView = (url: URL) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'page-view',
    properties: {
      page_path: url,
    },
  });
};

export function event<Event, Properties>(): GTagEventModel<Event, Properties> {
  const trafficType = process.env.REACT_APP_ENV === PRODUCTION ? {} : { traffic_type: 'internal' };

  return (event: string, properties: Record<any, any>) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event, properties: { ...properties, ...trafficType } });
  };
}
