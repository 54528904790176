import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'sticky',
      top: 0,
      zIndex: theme.zIndex.appBar,
    },
    contentContainer: {
      boxShadow: 'none',
      background: theme.palette.neutral?.main,
      position: 'unset',
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
      backgroundColor: theme.palette.secondary.main,
    },
    profileContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    userName: {
      marginLeft: theme.spacing(2.5),
    },
    avatar: {
      ...theme.typography.caption,
      color: theme.palette.common.white,
      fontWeight: theme.typography?.fontWeightBold as 'bold',
      backgroundColor: theme.palette.secondary?.light,
    },
    logo: {
      maxHeight: '2.5rem',
      width: 'auto',
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
  })
);
