import firebase from 'firebase/app';

import 'firebase/remote-config';
import { firebaseRemoteConstant } from '../../common/constants/firebase-remote-constants';
import { isProductionFlag } from '../../common/helpers/is-production-flag/is-production-flag';
import { defaultValues } from './default-values';

export function initFetchConfig() {
  const remoteConfig = firebase.remoteConfig();

  remoteConfig.settings.minimumFetchIntervalMillis = 900000;
  remoteConfig.defaultConfig = defaultValues;
  remoteConfig.fetchAndActivate();
}

export const getFirebaseRemoteAsBoolean = (key: firebaseRemoteConstant) => {
  const remoteConfig = firebase.remoteConfig();
  return isProductionFlag()
    ? remoteConfig.getValue(key).asBoolean()
    : (defaultValues[key] as boolean);
};
