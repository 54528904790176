export type PossibleTypesResultData = {
  possibleTypes: {
    Node: [
      'AccountsUser',
      'AdditionalChargeParameter',
      'Address',
      'Brand',
      'BrandTheme',
      'BrandThemeColor',
      'BrandThemeMessage',
      'CalendarTimeRule',
      'Card',
      'CardBrand',
      'Category',
      'Company',
      'CompanyCategory',
      'CompanyGroup',
      'CompanyPhone',
      'CostumerDocument',
      'Customer',
      'Document',
      'ExceptionTimeRule',
      'MenuCategory',
      'MenuProduct',
      'MenuProductCompany',
      'Order',
      'OrderExtra',
      'OrderField',
      'OrderFieldParameter',
      'OrderItem',
      'OrderTypeParameter',
      'Parameter',
      'Payment',
      'PaymentParameter',
      'Product',
      'ProductCompany',
      'Promotion',
      'Reward',
      'TemporaryUser',
      'TimeRule',
      'User',
      'WeekedayTimeRule'
    ];
    Person: ['Customer', 'TemporaryUser'];
  };
};
const result: PossibleTypesResultData = {
  possibleTypes: {
    Node: [
      'AccountsUser',
      'AdditionalChargeParameter',
      'Address',
      'Brand',
      'BrandTheme',
      'BrandThemeColor',
      'BrandThemeMessage',
      'CalendarTimeRule',
      'Card',
      'CardBrand',
      'Category',
      'Company',
      'CompanyCategory',
      'CompanyGroup',
      'CompanyPhone',
      'CostumerDocument',
      'Customer',
      'Document',
      'ExceptionTimeRule',
      'MenuCategory',
      'MenuProduct',
      'MenuProductCompany',
      'Order',
      'OrderExtra',
      'OrderField',
      'OrderFieldParameter',
      'OrderItem',
      'OrderTypeParameter',
      'Parameter',
      'Payment',
      'PaymentParameter',
      'Product',
      'ProductCompany',
      'Promotion',
      'Reward',
      'TemporaryUser',
      'TimeRule',
      'User',
      'WeekedayTimeRule',
    ],
    Person: ['Customer', 'TemporaryUser'],
  },
};
export default result;
