import { messageAlertReducers } from './message-alert.model';

export const messageAlertReducer: messageAlertReducers = {
  openMessage(state, action) {
    state.push({ ...action.payload, index: state.length });
  },
  closeMessage(state, action) {
    state.splice(action.payload.index, 1);
  },
};
