import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { Progress } from 'pp-admin-commons';

import { useStyles } from './progress-box.styles';

interface Props {
  loadingText: string;
  customStyles?: any;
}

export default function ProgressBox({ loadingText, customStyles }: Props) {
  const classes = useStyles();

  return (
    <Box className={`${classes.boxProgress} ${customStyles}`}>
      <Progress />
      <Typography className={classes.text} variant='body2' color='textSecondary'>
        {loadingText}
      </Typography>
    </Box>
  );
}
