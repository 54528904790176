import config from 'react-global-configuration';

import { AppConfig, Endpoints } from '../../model/config';

export function getAppConfig(): AppConfig {
  return config.get('appConfig');
}

export function getEndpoints(): Endpoints {
  return config.get('endpoints');
}
