import { isTokenExpired } from '../is-token-expired/is-token-expired';

export const isAuthenticated = () => {
  try {
    const token = window.localStorage.getItem('onyo-token')?.split(' ')[1];

    if (isTokenExpired(token)) {
      window.localStorage.removeItem('onyo-token');
      return false;
    }
    return true;
  } catch {
    return false;
  }
};
