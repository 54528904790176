import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const ENDPOINT = process.env.REACT_APP_MENU_SERVICES;

export const baseQuery = fetchBaseQuery({
  baseUrl: 'https://api-dev.pedepronto.com.br/v1/admin',
  prepareHeaders: (headers) => {
    return headers;
  },
});
