import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      textAlign: 'center',
      fontWeight: theme.typography?.fontWeightMediumBold,
    },
    dialogTitleTxt: {
      fontWeight: theme.typography?.fontWeightMediumBold,
    },
    dialogTxtContainer: {
      maxWidth: '27rem',
    },
    confirmationButton: {
      marginLeft: theme.spacing(2),
    },
    buttonContainer: {
      marginTop: theme.spacing(1.5),
      display: 'flex',
      justifyContent: 'flex-end',
    },
  })
);
