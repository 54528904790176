import { DefaultActionReducer } from '../../common/types/common-redux-models';
import { DraggableCategoriesModel } from '../../features/main-menu/model/draggable-categories';

export type modalCategoryActions = 'toggleCategory';

export interface ModalCategoryState {
  category?: DraggableCategoriesModel;
  open?: boolean;
  isEdit?: boolean;
  categories?: DraggableCategoriesModel[];
}

export type modalCategoryReducers = Record<
  modalCategoryActions,
  DefaultActionReducer<ModalCategoryState, ModalCategoryState>
>;

export const modalCategoryInitialState: ModalCategoryState = {
  category: {},
  open: false,
  isEdit: false,
  categories: [],
};
