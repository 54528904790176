import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../config/header';
import {
  CategoriesRequest,
  CategoriesResponse,
  CategoriesUpdateRequest,
  CaregoriesDeleteRequest,
  CaregoriesPathRequest,
} from '../models/categories';

const subpath = '/merchant';

export const categoriesApi = createApi({
  reducerPath: 'categoriesApi',
  baseQuery,
  endpoints: (builder) => ({
    getCategories: builder.query<CategoriesResponse[], string>({
      query: (externalMerchantId) => `${subpath}/${Number(externalMerchantId)}/categories`,
    }),
    postCategory: builder.mutation<CategoriesResponse, CategoriesRequest>({
      query: (body) => ({
        url: `${subpath}/${body.externalMerchantId}/categories`,
        method: 'POST',
        body: { name: body.name, index: body.index, externalCategoryId: body.externalCategoryId },
      }),
    }),
    updateCategory: builder.mutation<CategoriesResponse, CategoriesUpdateRequest>({
      query: (body) => ({
        url: `${subpath}/${body.externalMerchantId}/categories/${body.categoryId}`,
        method: 'PUT',
        body: {
          name: body.name,
          index: body.index,
          externalCategoryId: body.externalCategoryId,
          active: body.active,
        },
      }),
    }),
    deleteCategory: builder.mutation<null, CaregoriesDeleteRequest>({
      query: (params) => ({
        url: `${subpath}/${params.externalMerchantId}/categories/${params.categoryId}`,
        method: 'DELETE',
      }),
    }),
    patchCategory: builder.mutation<null, CaregoriesPathRequest>({
      query: (params) => ({
        url: `${subpath}/${params.externalMerchantId}/categories/${params.categoryId}/correlate/${params.externalCategoryId}`,
        method: 'PATCH',
      }),
    }),
  }),
});

export const {
  useGetCategoriesQuery,
  usePostCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
  usePatchCategoryMutation,
} = categoriesApi;
