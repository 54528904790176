import { configureStore } from '@reduxjs/toolkit';

import { rootQueryReducer, middleware } from '../api/root-query-reducer';
import { rootReducer } from '../store/root-reducer';

export const store = configureStore({
  reducer: {
    ...rootQueryReducer,
    ...rootReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware),
  devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
