import React, { ReactElement } from 'react';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';

import { useCreateCustomTheme } from '../../hooks/theme/create-custom-theme';

export default function ThemeHandler(props: React.PropsWithChildren<any>): ReactElement {
  const customTheme = useCreateCustomTheme();

  return <MuiThemeProvider theme={createMuiTheme(customTheme)}>{props.children}</MuiThemeProvider>;
}
