import React from 'react';

import { Button } from '@material-ui/core';

import { ColorOptions, SizeOptions } from '../../model/theme-options';
import { useStyles, colorStyles, sizeStyles } from './generic-button.styles';

interface Props {
  size?: SizeOptions;
  id: string;
  children: React.ReactNode;
  color?: ColorOptions;
  disabled?: boolean;
  onClick?(event: React.MouseEvent<HTMLButtonElement>): void;
  type?: 'submit' | 'reset' | 'button';
  className?: string;
}

export default function GenericButton(props: Props) {
  const sizeClassSelected = props.size ?? 'medium';
  const colorClassSelected = props.color ?? 'default';
  const classes = useStyles();
  const colorClasses = colorStyles();
  const sizeClasses = sizeStyles();

  return (
    <Button
      id={props.id}
      type={props.type}
      data-testid={props.id}
      onClick={props.onClick}
      disabled={props.disabled}
      className={`${colorClasses[colorClassSelected]} ${props.className} ${sizeClasses[sizeClassSelected]} ${classes.container}`}
    >
      {props.children}
    </Button>
  );
}
