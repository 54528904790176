import { gql, InMemoryCache } from '@apollo/client';

import result from '../../common/generated/fragment-matcher';
import { logout } from '../../common/graphql/policies/logout/logout.policy';

export const localCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        logout,
      },
    },
  },
  possibleTypes: result.possibleTypes,
});

export const LocalCacheInitQuery = gql`
  query LocalCacheInit {
    cachedImages
    brandSelected
    companySelected
    accountsCachedData
    businessCachedData
    addressCachedData
    isGmapsReady
    addressLatLongCachedData
  }
`;

export function initialLocalCache() {
  localCache.writeQuery({
    query: LocalCacheInitQuery,
    data: {
      cachedImages: null,
      brandSelected: null,
      companySelected: null,
      accountsCachedData: null,
      businessCachedData: null,
      addressCachedData: null,
      isGmapsReady: false,
      addressLatLongCachedData: null,
    },
  });
}
