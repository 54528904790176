import { ApolloLink } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

import { getEndpoints } from '../../common/helpers/configs/configs';
import { customFetch } from './custom-fetch';

export function createHttpLink(): ApolloLink {
  const endpoints = getEndpoints();
  return createUploadLink({
    credentials: 'include',
    uri: endpoints.graphql,
    fetch: customFetch as any,
  }) as unknown as ApolloLink;
}
