import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boxProgress: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      padding: theme.spacing(4),
    },
    text: {
      margin: theme.spacing(4, 0),
    },
  })
);
