import { useCallback } from 'react';

import { useApolloClient } from '@apollo/client';

import { GetIsGmapsReadyDocument, GetIsGmapsReadyQuery } from '../../generated/graphql';

interface GmapsIsReady {
  (): {
    onGmapsIsReady: VoidFunction;
  };
}

export const useGmapsIsReady: GmapsIsReady = () => {
  const client = useApolloClient();

  const onGmapsIsReady = useCallback(() => {
    client.writeQuery<GetIsGmapsReadyQuery>({
      query: GetIsGmapsReadyDocument,
      data: {
        isGmapsReady: true,
      },
    });
  }, [client]);

  return {
    onGmapsIsReady,
  };
};
