import React, { ReactElement } from 'react';

import { Box, Typography } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';

import { useStyles } from './contact-us.styles';

export default function ContactUs(): ReactElement {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box display='flex' justifyContent='center' flexDirection='column'>
        <PhoneIcon className={classes.helpIcon} color='primary' />
        <EmailIcon className={classes.helpIcon} color='primary' />
      </Box>
      <Box display='flex' justifyContent='center' flexDirection='column'>
        <a href='tel: 01140201739' className={classes.helpText}>
          <Typography>(11) 4020-1739</Typography>
        </a>
        <a href='mailto: ajuda@pedepronto.com.br' className={classes.helpText}>
          <Typography>ajuda@pedepronto.com.br</Typography>
        </a>
      </Box>
    </Box>
  );
}
