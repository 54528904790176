import { DefaultActionReducer } from '../../common/types/common-redux-models';

export type messageAlertActions = 'openMessage' | 'closeMessage';

export interface messageAlertState {
  type?: string;
  title?: string;
  text?: string;
  index?: number;
}

export type messageAlertReducers = Record<
  messageAlertActions,
  DefaultActionReducer<messageAlertState[], messageAlertState>
>;

export const messageAlertInitialState: messageAlertState[] = [];
