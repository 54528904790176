import React, { MutableRefObject, ReactElement, useCallback } from 'react';

import { FormControl, FormHelperText, OutlinedInput, FormLabel } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';

import { formatCNPJ } from '../../helpers/format-cnpj/format-cnpj';
import { formatCPF } from '../../helpers/format-cpf/format-cpf';
import { formatPhoneNumber } from '../../helpers/format-phone-number/format-phone-number';
import { formatZipCode } from '../../helpers/format-zip-code/format-zip-code';
import { getOnlyDigits } from '../../helpers/get-only-digits/get-only-digits';
import { FieldFormatType } from '../../model/field-format-type';
import { HandleOnBlur } from '../../model/html-events';
import { useStyles } from './generic-text-field.styles';

interface Props {
  id: string;
  label: string;
  name: string;
  fieldType: string;
  inputRef?: MutableRefObject<HTMLInputElement | null>;
  placeholder?: string;
  fieldFormatType?: FieldFormatType;
  className?: any;
  multiline?: boolean;
  rows?: number;
  hideError?: boolean;
  readOnly?: boolean;
  autoFocus?: boolean;
  maxLength?: number;
  onBlur?: VoidFunction;
  disabled?: boolean;
  autoComplete?: string;
}

export default function GenericTextField(props: Props): ReactElement {
  const classes = useStyles();
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

  const handleChange = useCallback(
    (event) => {
      switch (props.fieldFormatType) {
        case FieldFormatType.NUMERICAL:
          setFieldValue(field.name, getOnlyDigits(event.target.value));
          break;
        case FieldFormatType.CPF:
          setFieldValue(field.name, formatCPF(event.target.value));
          break;
        case FieldFormatType.ZIPCODE:
          setFieldValue(field.name, formatZipCode(event.target.value));
          break;
        case FieldFormatType.CNPJ:
          setFieldValue(field.name, formatCNPJ(event.target.value));
          break;
        case FieldFormatType.TELEPHONE:
          setFieldValue(field.name, formatPhoneNumber(event.target.value));
          break;
        default:
          setFieldValue(field.name, event.target.value);
          break;
      }
    },
    [field.name, props, setFieldValue]
  );

  const handleOnBlur: HandleOnBlur = useCallback(
    (e) => {
      field.onBlur(e);
      if (props?.onBlur) {
        props.onBlur();
      }
    },
    [field, props]
  );

  return (
    <FormControl fullWidth={true} error={meta.error && meta.touched ? true : false}>
      <FormLabel className={classes.label} htmlFor={props.id}>
        {props.label}
      </FormLabel>
      <OutlinedInput
        id={props.id}
        className={`${props.className} ${classes.input}`}
        classes={{
          disabled: classes.disabled,
        }}
        name={props.name}
        placeholder={props.placeholder}
        type={props.fieldType}
        value={field.value}
        multiline={props.multiline}
        rowsMax={props.rows}
        inputRef={props.inputRef}
        onChange={handleChange}
        onBlur={handleOnBlur}
        error={meta.error && meta.touched ? true : false}
        readOnly={props.readOnly}
        disabled={props.disabled}
        autoFocus={props.autoFocus}
        inputProps={{ maxLength: props.maxLength }}
        autoComplete={props.autoComplete}
      />

      {!props.hideError && meta.error && (
        <FormHelperText id={`${props.id}-helper-text`}>{meta.error}</FormHelperText>
      )}
    </FormControl>
  );
}
