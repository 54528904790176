import React, { useCallback, useState } from 'react';

import { Helmet } from 'react-helmet';

import { getAppConfig } from '../../helpers/configs/configs';
import { useGmapsIsReady } from '../../hooks/use-gmaps-is-ready/use-gmaps-is-ready';
import { SelfJourneyLocationOptions } from '../../model/self-journey-location-options';
import SelfJourneyPageContext from '../crop-handler/contexts/self-journey-page-context';

export default function PageSetup(props: React.PropsWithChildren<any>) {
  const [currentSelfJourneyPage, setCurrentSelfJourneyPage] =
    useState<SelfJourneyLocationOptions | null>(null);
  const { onGmapsIsReady } = useGmapsIsReady();
  const { gmapsSrc } = getAppConfig();

  const handleSetCurrentSelfJourneyPage = useCallback((page: SelfJourneyLocationOptions) => {
    setCurrentSelfJourneyPage(page);
  }, []);

  (window as any).initMaps = useCallback(() => {
    onGmapsIsReady();
  }, [onGmapsIsReady]);

  return (
    <SelfJourneyPageContext.Provider
      value={{ currentSelfJourneyPage, setCurrentSelfJourneyPage: handleSetCurrentSelfJourneyPage }}
    >
      <div>
        <Helmet>
          <script async defer src={gmapsSrc}></script>
        </Helmet>
        {props.children}
      </div>
    </SelfJourneyPageContext.Provider>
  );
}
