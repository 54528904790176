import { createSlice } from '@reduxjs/toolkit';

import { modalCategoryInitialState } from './modal-category.model';
import { modalCategoryReducer } from './modal-category.reducers';

const modalCategorySlice = createSlice({
  name: 'modalCategory',
  initialState: modalCategoryInitialState,
  reducers: modalCategoryReducer,
});

export const modalCategoryReducers = modalCategorySlice.reducer;
export const modalCategoryName = modalCategorySlice.name;
export const { toggleCategory } = modalCategorySlice.actions;
