import * as Sentry from '@sentry/browser';

import { getAppConfig } from '../common/helpers/configs/configs';

export function initSentry() {
  const config = getAppConfig();
  if (config.sentryDSN && config.sentryDSN !== '') {
    Sentry.init({
      release: process.env.REACT_APP_VERSION,
      dsn: config.sentryDSN,
    });
  }
}
