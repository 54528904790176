import React, { ReactElement, useEffect, useState } from 'react';

import {
  useTheme,
  List,
  ListItem,
  ListItemIcon,
  Drawer,
  Hidden,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { sidebarItems } from 'pp-admin-commons';
import { Link, useLocation } from 'react-router-dom';

import Logo from '../../../assets/pede-pronto-logo-large.png';
import { isProductionFlag } from '../../helpers/is-production-flag/is-production-flag';
import { SidebarItems } from '../../model/sidebar-items';
import { useStyles } from './sidebar.styles';

interface Props {
  mobileOpen: boolean;
  handleDrawerToggle: VoidFunction;
  isSuperUser: boolean;
}

export default function SideBar(props: Props): ReactElement {
  const classes = useStyles();
  const theme = useTheme();
  const [sidebar, setSidebar] = useState<SidebarItems[]>();

  const { pathname } = useLocation();

  useEffect(() => {
    setSidebar(sidebarItems(props.isSuperUser, isProductionFlag()));
  }, []);

  /**
   * @todo This is a temporary snippet of code to
   * present all administration pages available only to operations staff
   */
  const sidebarContent = (
    <>
      <List className={classes.listContainer}>
        {sidebar?.map((menu) => (
          <ListItem
            key={menu.name}
            classes={{ root: classes.listItem, selected: classes.listItemSelected }}
            selected={pathname.includes(menu.path)}
          >
            <Link className={classes.link} to={menu.path}>
              <ListItemIcon className={classes.icon}>{menu.icon}</ListItemIcon>
              <ListItemText>
                <Typography className={classes.sidebarItems}>{menu.name}</Typography>
              </ListItemText>
            </Link>
          </ListItem>
        ))}
      </List>
    </>
  );

  return (
    <nav className={classes.container}>
      <Hidden implementation='css'>
        <Drawer
          variant='temporary'
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={props.mobileOpen}
          onClose={props.handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <>
            <img className={classes.logo} src={Logo} alt='Pede Pronto' />
            {sidebarContent}
          </>
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation='css'>
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant='permanent'
          open
        >
          {sidebarContent}
        </Drawer>
      </Hidden>
    </nav>
  );
}
