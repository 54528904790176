import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette?.neutral?.light,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(10),
        flexShrink: 0,
        [theme.breakpoints.up('md')]: {
          width: theme.spacing(30),
        },
      },
    },
    link: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    drawerPaper: {
      backgroundColor: theme.palette?.neutral?.light,
      border: 'none',
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(8),
        paddingLeft: theme.spacing(3),
        height: 'calc(100% - 4rem)',
        top: '4.0625rem',
        [theme.breakpoints.up('md')]: {
          width: theme.spacing(30),
          padding: 0,
        },
      },
    },
    logo: {
      margin: theme.spacing(2),
      height: 'auto',
      maxWidth: '7.5rem',
    },
    sidebarItems: {
      fontWeight: theme.typography.fontWeightBold as 'bold',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
      display: 'block',
    },
    icon: {
      minWidth: '2.5rem',
    },
    listContainer: { overflowX: 'hidden' },
    listItem: {
      borderRight: `2px solid ${theme.palette?.neutral?.light}`,
      '&$listItemSelected': {
        backgroundColor: theme.palette?.neutral?.light,
        color: theme.palette.primary.dark,
        borderRight: `2px solid ${theme.palette.primary.dark}`,
        '& .MuiListItemIcon-root': {
          color: theme.palette.primary.dark,
        },
        '& .MuiListItemText-root': {
          color: theme.palette.primary.dark,
          '& .MuiTypography-body1': { color: theme.palette.primary.dark },
        },
      },
      '&$listItemSelected:hover': {
        backgroundColor: theme.palette?.neutral?.light,
        color: theme.palette.primary.main,
        '& .MuiListItemIcon-root': {
          color: theme.palette.primary.main,
        },
        '& .MuiListItemText-root': {
          color: theme.palette.primary.main,
          '& .MuiTypography-body1': { color: theme.palette.primary.main },
        },
      },
      '&:hover': {
        backgroundColor: theme.palette?.neutral?.light,
        color: theme.palette.primary.main,
        '& .MuiListItemIcon-root': {
          color: theme.palette.primary.main,
        },
        '& .MuiListItemText-root': {
          color: theme.palette.primary.main,
          '& .MuiTypography-body1': { color: theme.palette.primary.main },
        },
      },
    },
    listItemSelected: {},
  })
);
