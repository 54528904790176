import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContainer: {
      '& .MuiDialog-paper': {
        maxWidth: '32.875rem',
      },
      '& .MuiDialogTitle-root .MuiTypography-root': {
        fontSize: '1.5rem',
      },
    },
    modalContainerLoading: {
      height: '13.5rem',
    },
    containerForm: {
      marginBottom: theme.spacing(3),
    },
    containerButtons: {
      display: 'flex',
      marginTop: theme.spacing(8.75),
      paddingBottom: theme.spacing(3),
      justifyContent: 'flex-end',
    },
    buttons: {
      minWidth: '10.625rem',
      marginLeft: theme.spacing(2),
    },
    maxLength: {
      display: 'flex',
      justifyContent: 'flex-end',
      color: theme.typography?.color?.light,
    },
    inputCategory: {
      marginTop: theme.spacing(1),
    },
  })
);
