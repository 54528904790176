import React from 'react';

import { Box, CircularProgress } from '@material-ui/core';

import { useStyles } from './progress.styles';

interface Props {
  className?: string;
  id?: string;
  size?: number;
}

export default function Progress({ id, className, size }: Props) {
  const classes = useStyles();

  return (
    <Box display='flex' justifyContent='center'>
      <CircularProgress
        id={id}
        color='primary'
        size={size}
        className={`${classes.progress} ${className}`}
      />
    </Box>
  );
}
