import config from 'react-global-configuration';
import TagManager from 'react-gtm-module';

import { DEVELOPMENT, Endpoints, PRODUCTION, AppConfig, STAGING } from '../common/model/config';
import { developmentAppConfig } from './application/app-configurations/development.config';
import { productionAppConfig } from './application/app-configurations/production.config';
import { stagingAppConfig } from './application/app-configurations/staging.config';
import { developmentEndpoints } from './application/endpoint-configurations/development.endpoint.config';
import { productionEndpoints } from './application/endpoint-configurations/production.endpoint.config';
import { stagingEndpoints } from './application/endpoint-configurations/staging.endpoint.config';
import { initFirebase } from './firebase';
import { initSentry } from './sentry';

function globalConfig() {
  const appConfig = getAppConfig();
  const endpoints = getEndpoints();
  const tagManagerArgs = {
    gtmId: appConfig.gtmId,
  };

  TagManager.initialize(tagManagerArgs);

  config.set({ appConfig, endpoints });

  initSentry();
  initFirebase();
}

export default globalConfig();

export function getAppConfig(): AppConfig {
  const env = process.env.REACT_APP_ENV;
  switch (env) {
    case PRODUCTION:
      return productionAppConfig;
    case STAGING:
      return stagingAppConfig;
    case DEVELOPMENT:
      return developmentAppConfig;
    default:
      return developmentAppConfig;
  }
}

function getEndpoints(): Endpoints {
  const env = process.env.REACT_APP_ENV;

  if (env === DEVELOPMENT) {
    return developmentEndpoints;
  } else {
    return env === PRODUCTION ? productionEndpoints : stagingEndpoints;
  }
}
