import { createSlice } from '@reduxjs/toolkit';

import { messageAlertInitialState } from './message-alert.model';
import { messageAlertReducer } from './message-alert.reducers';

const messageAlertSlice = createSlice({
  name: 'messageAlert',
  initialState: messageAlertInitialState,
  reducers: messageAlertReducer,
});

export const messageAlertReducers = messageAlertSlice.reducer;
export const messageAlertName = messageAlertSlice.name;
export const { openMessage, closeMessage } = messageAlertSlice.actions;
