import React, { ReactElement } from 'react';

import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';

import ContactInfo from '../contact-info/contact-info';
import GenericButton from '../generic-button/generic-button';
import { useStyles } from './standard-dialog.styles';

interface Props {
  id: string;
  open: boolean;
  title: string;
  text: string;
  onCloseClick?: VoidFunction;
  closeLabel?: string;
  onConfirmClick?: VoidFunction;
  disableBackdropClick?: boolean;
}

export default function StandardDialog({
  id,
  open,
  title,
  text,
  onCloseClick,
  onConfirmClick,
  closeLabel = 'Fechar',
  disableBackdropClick,
}: Props): ReactElement {
  const classes = useStyles();

  return (
    <Dialog id={id} disableBackdropClick={disableBackdropClick} onClose={onCloseClick} open={open}>
      <DialogTitle className={classes.dialogTitle}>
        <Typography className={classes.dialogTitleTxt}>{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <Box className={classes.dialogTxtContainer}>
          <Typography variant='body2'>{text}</Typography>
        </Box>
        <Box className={classes.buttonContainer}>
          {onCloseClick && (
            <GenericButton id='close-button' onClick={onCloseClick} color='transparent'>
              <Typography variant='body2' color='inherit'>
                {closeLabel}
              </Typography>
            </GenericButton>
          )}
          {onConfirmClick && (
            <GenericButton
              id='confirmation-button'
              className={classes.confirmationButton}
              onClick={onConfirmClick}
              color='error'
            >
              <Typography variant='body2' color='inherit'>
                Confirmar
              </Typography>
            </GenericButton>
          )}
        </Box>
        <ContactInfo />
      </DialogContent>
    </Dialog>
  );
}
