import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      boxSizing: 'border-box',
      padding: theme.spacing(0, 2),
      borderRadius: theme.spacing(0.5),
      margin: theme.spacing(2, 0, 3, 0),
      alignItems: 'center',
      height: '4rem',
    },
    title: {
      fontWeight: theme.typography.fontWeightMediumBold,
      color: theme.typography?.color?.dark,
    },
    subtitle: {
      color: theme.typography?.color?.dark,
    },
    icon: {
      margin: theme.spacing(1, 1.5, 0, 0),
    },
  })
);

export const colorStyles = makeStyles((theme: Theme) =>
  createStyles({
    warning: {
      border: `1px solid ${theme.palette.warning.main}`,
      backgroundColor: theme.palette.warning.superLightest,
    },
    error: {
      border: `1px solid ${theme.palette.error.darkest}`,
      backgroundColor: theme.palette.error.light,
    },
  })
);
