import React from 'react';

import { Box, Typography } from '@material-ui/core';

import { useStyles } from './contact-info.styles';

export default function ContactInfo() {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography variant='caption' align='center'>
        Precisa de ajuda? Entre em contato com a nossa equipe de suporte{' '}
        <strong>ajuda@pedepronto.com.br</strong> ou <strong>4020-1739</strong>.
      </Typography>
    </Box>
  );
}
