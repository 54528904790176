import jwt from 'jwt-decode';

export const getUserId = (token?: string | null) => {
  if (!token) {
    return null;
  }

  const decoded: any = jwt(token);

  return decoded.identity.userId;
};
