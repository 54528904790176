import { useCallback } from 'react';

import { useApolloClient } from '@apollo/client';

import { LogoutQuery, LogoutDocument } from '../../generated/graphql';

interface Logout {
  (): {
    onLogout: VoidFunction;
  };
}

export const useLogout: Logout = () => {
  const client = useApolloClient();

  const onLogout = useCallback(() => {
    client.writeQuery<LogoutQuery>({
      query: LogoutDocument,
      data: {
        logout: null,
      },
    });
  }, [client]);

  return {
    onLogout,
  };
};
