import { getOnlyDigits } from '../get-only-digits/get-only-digits';

/**
 * formats the Cnpj as XX.XXX.XXX/XXXX-XX
 */
export function formatCNPJ(cnpj?: string): string {
  if (!cnpj) {
    return '';
  }

  const numCNPJ = getOnlyDigits(cnpj);

  if (numCNPJ.length > 12) {
    return `${numCNPJ.substring(0, 2)}.${numCNPJ.substring(2, 5)}.${numCNPJ.substring(
      5,
      8
    )}/${numCNPJ.substring(8, 12)}-${numCNPJ.substring(12, 14)}`;
  } else if (numCNPJ.length > 8) {
    return `${numCNPJ.substring(0, 2)}.${numCNPJ.substring(2, 5)}.${numCNPJ.substring(
      5,
      8
    )}/${numCNPJ.substring(8)}`;
  } else if (numCNPJ.length > 5) {
    return `${numCNPJ.substring(0, 2)}.${numCNPJ.substring(2, 5)}.${numCNPJ.substring(5)}`;
  } else if (numCNPJ.length > 2) {
    return `${numCNPJ.substring(0, 2)}.${numCNPJ.substring(2)}`;
  } else {
    return numCNPJ;
  }
}
