import React, { useCallback, useState } from 'react';

import { Box, Menu, MenuItem } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { useLogout } from '../../hooks/use-logout/use-logout';
import GenericActionButton from '../generic-action-button/generic-action-button';

export default function ProfileMenu() {
  const history = useHistory();
  const { onLogout } = useLogout();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleSettings = useCallback(() => {
    history.push('/settings');
  }, [history]);

  const handleLogout = useCallback(() => {
    onLogout();
    window.location.reload();
  }, [onLogout]);

  return (
    <Box>
      <GenericActionButton
        id='profile-menu-button'
        aria-controls='simple-menu'
        aria-haspopup='true'
        onClick={handleClick}
        name='angleDown'
        color='default'
      />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem id='profile-menu-item-settings' onClick={handleSettings}>
          Conta
        </MenuItem>
        <MenuItem id='profile-menu-item-logout' onClick={handleLogout}>
          Sair
        </MenuItem>
      </Menu>
    </Box>
  );
}
